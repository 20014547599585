<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<el-row>
				<el-col class="yu_input_label" :span="7">
					用户id:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_id"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="7">
					用户昵称:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_nickname" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			
  
			<el-row>
				<el-col class="yu_input_label" :span="7">
					收货地址:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_shouhuo_dizhi"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="7">
					收货人:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_shouhuo_ren"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="7">
					收货人手机号:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_shouhuo_shouji"></el-input>
				</el-col>
			</el-row>
			<br />
			 
			
			<el-row>
				<el-col class="yu_input_label" :span="7">
					积分:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_jifen"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row class="yu_content_right">
				<el-button @click="user_info_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import user_info_get  from "./expand/user_info_get.js";
	import user_info_post from "./expand/user_info_post.js";

	export default {

		props: ["m_user_id", "parent"],
		data() {
			return {
				m_xingqi_no: 1, 
				m_old_id : 0,
				m_old_biaoqian : "", 
				m_user_nickname : "",
				m_user_biaoqian:"", 
				m_user_shouhuo_dizhi:"", 
				m_old_user_shouhuo_dizhi:"", 
				m_user_shouhuo_ren:"", 
				m_old_user_shouhuo_ren:"", 
				m_user_shouhuo_shouji:"", 
				m_old_user_shouhuo_shouji:"", 
				m_user_jifen:"",  
				m_old_user_jifen:"", 
			}
		},
		created() {
			const that = this;
			that.m_old_id = that.m_user_id;
			that.user_info_get = new user_info_get(that);
			that.user_info_get.m_main();
		},
		methods: {
			user_info_set() {
				const that = this;
				that.user_info_post = new user_info_post(that);
				that.user_info_post.m_main();
			} 
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>